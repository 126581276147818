/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import { isMobile } from 'react-device-detect';
import { useCookies } from 'react-cookie';

import routes from '../libs/routes';
import images from "../libs/images";

import PopupMain from "../pages/popup/PopupMain";

import { setData as setEtcData } from '../redux/etcSlice';

export default function(props) {
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);

    const [cookies, setCookie, removeCookie] = useCookies(["bookmark"]);

    const [menuTab, setMenuTab] = useState(props.curr_menu); 
    const [menuOpen, setMenuOpen] = useState(false); 
    const [isMypageOpen, setMypageOpen] = useState(false); 
    const [isCustomerOpen, setCustomerOpen] = useState(false); 
    
    const [isMainMenu, setMainMenu] = useState(true); 
    
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [isScrolled, setIsScrolled] = useState(false);
    const [isResize, setReszie] = useState(true);
    const excludedRoutes = [routes.login, routes.find, routes.register01, routes.register02,"/find/id","/find/pw","/check"];
    const handleScroll = () => {
        if(
            location.pathname !== '/login' && 
            location.pathname !== '/agree' && 
            location.pathname !== '/register'
        ) {
            setIsScrolled(window.innerWidth >= 800 && window.scrollY > 120);
        }
    };
    const handleResize = () => {
        setMainMenu(true);
        if(window.innerWidth >= 800){
            if(
                location.pathname.indexOf("/notice") >= 0 || 
                location.pathname.indexOf("/faq") >= 0 || 
                location.pathname.indexOf("/my") >= 0 || 
                location.pathname.indexOf("/event") >= 0 
             ){
                setMainMenu(false);
            }
        }
        setReszie(window.innerWidth >= 800);
    }

    useEffect(()=>{
        setInCate(false);
        setView(false);
        setIsCateshow(false); 
        setMenuTab(-1);       
    },[location.pathname]);

    /*
    
    useEffect(() => {
        const curr_num = Number(props.curr_menu);
        setMenuTab(-1);

        let  menu_title='';
        if(curr_num===0){           
            menu_title='국어';
        } else if(curr_num===1){
            menu_title='사회';
        } else if(curr_num===2){
            menu_title='미술';
        } 
        setMenu(menu_title);
    },[]);
    */



    useEffect(() => {
        
        setMainMenu(true);
        if(window.innerWidth >= 800){
            if(
                location.pathname.indexOf("/notice") >= 0 || 
                location.pathname.indexOf("/faq") >= 0 || 
                location.pathname.indexOf("/my") >= 0 || 
                location.pathname.indexOf("/event") >= 0 
             ){
                setMainMenu(false);
            }
        }
        setReszie(window.innerWidth >= 800); 
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }; 
    });
    useEffect(() => { 
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        
    })
    useEffect(() => {
        setMenuOpen(false);
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const [isCateshow, setIsCateshow] = useState(false);
    const [subCate, setSubCate] = useState(1);
    const [view, setView] = useState(false);
    const [menu, setMenu] = useState("국어");
    const [submenu, setSubMenu] = useState();
    
    const CateClick = (tab) => {
        if(window.innerWidth >= 800){
        setInCate(true);
        let  menu_title='';
        //setSubMenu
        if(tab===0){           
            menu_title='국어';
        } else if(tab===1){
            menu_title='사회';
        } else if(tab===2){
            menu_title='미술';
        } 

        if(menuTab == tab){
            setView(!isCateshow);
            setIsCateshow(!isCateshow); 
            setMenuTab(-1);          
        }else{
            setView(true);
            setIsCateshow(true);     
            setMenuTab(tab);
            setMenu(menu_title);

        }
        setSubCate(1);
        setMenuOpen(false);
        if(tab >= 0){

            if(  props?.menu[menu_title] && props?.menu[menu_title]['초등']){
                setSubMenu("초등");
            } else {
                if( props?.menu[menu_title] && props?.menu[menu_title]['중학'] ){
                    setSubMenu("중학");
                }else{
                    setSubMenu("고등");
                }
            }
        }
        }
    };
    const CateClick1 = (tab) => {
        
        setInCate(true);
        let  menu_title='';
        //setSubMenu
        if(tab===0){           
            menu_title='국어';
        } else if(tab===1){
            menu_title='사회';
        } else if(tab===2){
            menu_title='미술';
        } 

        if(menuTab == tab){
            setView(!isCateshow);
            setIsCateshow(!isCateshow); 
            setMenuTab(-1);          
        }else{
            setView(true);
            setIsCateshow(true);     
            setMenuTab(tab);
            setMenu(menu_title);
        }
        setSubCate(1);
        setMenuOpen(false);
        if(tab >= 0){

            if(  props?.menu[menu_title] && props?.menu[menu_title]['초등']){
                setSubMenu("초등");
            } else {
                if( props?.menu[menu_title] && props?.menu[menu_title]['중학'] ){
                    setSubMenu("중학");
                }else{
                    setSubMenu("고등");
                }
            }
        }
    };

    const onSubMenuOpen = (key) => {
        setSubMenu(key);
    }

    const { eventTitle, topBanner, topBannerPc, topBannerMobile,topBannerUrl } = useSelector(s => s.etcReducer);
    const goMain = () => {
        setMenuTab(-1);
        setMenuOpen(false);
        setView(false);
        setIsCateshow(false);     
        navigate('/');
    }
    function goUrl(url) {
        setMenuOpen(false);
        navigate(url);
    }

    const onMenu = (no) =>{
        if(no === 3){
            navigate("/innovation");
        }
        if(no === 4){
            navigate("/creative");
        }
        if(no === 5){
            //navigate("/book");
            navigate("/book/list/3");
            
        }
        if(no === 6){
            navigate("/teacher");
        }
        setMenuTab(no);
        setMenuOpen(false);
        setIsCateshow(false);
        setSubCate(false);
        setView(false);
    }
    
    const onLogout = () =>{
        localStorage.removeItem("access");
        localStorage.removeItem("remail");
        localStorage.removeItem("page");
        localStorage.removeItem("sendData");
        dispatch(
            logOut()
        );
        navigate("/");
    }

    const bookmark = () =>{ 
        let title = "창비교육 :: 선생님을 위한 수업지원자료실";
        let url = "https://class.changbiedu.com";
        setCookie("bookmark", "star-fill"); 
            // Internet Explorer   
        if(document.all) {       
            window.external.AddFavorite(url, title);    
        }   
        // Google Chrome   
        else if(window.chrome) {      
            alert("Ctrl+D키를 누르시면 즐겨찾기에 추가하실 수 있습니다.");   
        }   
        // Firefox   
        else if (window.sidebar) {       
            window.sidebar.addPanel(title, url, "");    
        }   
        // Opera   
        else if(window.opera && window.print) {      
            var elem = document.createElement('a');
            elem.setAttribute('href',url);       
            elem.setAttribute('title',title);
            elem.setAttribute('rel','sidebar');       
            elem.click();    
        }
       
    }

    const onMenuOpen = () =>{
        setMenuOpen(!menuOpen);
        setIsCateshow(false);
        setView(false);
    }
    const subMenuClose = () => {
        setView(false);
        setIsCateshow(false); 
    }
    const onCurriculum = (val) => {
        if(val.name.indexOf("준비 중") >= 0 ){
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="준비중입니다."
                        button="확인"
                    />
                })
            );

        }else{
            setView(false);
            setIsCateshow(false); 
        
            let type = 0;
            if(val.subject==="국어"){
                type = 0;
            } else if(val.subject==="사회"){
                type = 1;
            } else if(val.subject==="미술"){
                type = 2;
            } 
            setMenuTab(type)
            navigate(routes.curriculum+"/"+type+"/"+val.subjectSeq,{state:val});
        }
                
    }

    const [inCate, setInCate] = useState(false);
    const [inMouse, setInMouse] = useState(false);

    const goCateOut = () => {
        //setInCate(false);
    }
    const inMouseMenu = () => {
        if(window.innerWidth >= 800){
            setInMouse(true);
        }
    }
    const outMouseMenu = () => {
        if(window.innerWidth >= 800){
        if(inCate && inMouse){
            setInCate(false);
            setInMouse(false);
            
            setView(!isCateshow);
            setIsCateshow(!isCateshow); 
            setMenuTab(-1);      
        }
        }
    }
    const goTopLink = () => {
        if(topBannerUrl){
            navigate(topBannerUrl);
        }
    }
    return (
        <div className="header_main_box">
            <div className={`header ${isScrolled ? 'fix' : ''}`}>
                {topBanner && (
                    <div className="header_top_pop">
                        

                        <div className={topBannerUrl ? "content point" : "content"} >
                            <img className="close" src='/images/top_close.svg' onClick={() => {
                                dispatch(
                                    setEtcData({
                                        key: 'topBanner',
                                        value: ""
                                    })
                                )
                            }}/>
                            <div className="banner_pc" onClick={()=>goTopLink()} style={{background: 'url('+topBannerPc+') no-repeat center center', backgroundSize: 'cover'}}></div>
                            <div className="banner_mobile" onClick={()=>goTopLink()} style={{background: 'url('+topBannerMobile+') no-repeat center center', backgroundSize: 'cover'}}></div>
                           

                        </div>
                    </div>
                )}

                {!isScrolled &&
                <div className="header_part1">
                    <div className="header_top">
                        <div className="header_top_sub">
                            <p className="sub_tap_name hv"><a href="https://textbook.changbiedu.com/" target="_blank">교과서</a></p>
                            <span style={{color: '#ccc', margin: '0 12px'}}>|</span>
                            <p className="sub_tap_name hv"><a href="https://books.changbiedu.com/" target="_blank">단행본</a></p>
                            <span style={{color: '#ccc', margin: '0 12px'}}>|</span>
                            <p className="sub_tap_name hv"><a href="https://teacher.changbiedu.com/" target="_blank">창비교육연수원</a></p>
                        </div>

                        <div className="header_top_sub right">
                            <a href="https://www.youtube.com/@user-tc9dt2zu6d" target="_blank" className="header_youtube" ><img src='/images/youtube-icon.svg' /></a>
                            <a href="https://blog.naver.com/changbiedu_book" target="_blank" className="header_naver"><img src='/images/blog-icon.svg' /></a>
                            { !isMobile &&
                            <div className="fav_btn" onClick={()=>bookmark()}>
                                <img src={'/images/'+(cookies.bookmark!== undefined ? 'star-fill' : 'star-line') + '.svg'} />
                                <p className="sub_tap_name hv">즐겨찾기 추가</p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                }

                {!isScrolled &&
                <div className="header_part2 pc">
                    <div className="header_part2_sub">
                        <div className="header_title_box" onClick={()=>goMain()}>
                            <p className="title">창비교육</p>
                            <div className="sub_title_box">
                                <p className="sub_title sm">선생님을 위한</p>
                                <p className="sub_title">수업지원자료실</p>
                            </div>
                        </div>

                        <div className="header_top_sub">
                            <p className="sub_tap_name hv" onClick={()=>goUrl('/notice')}>고객센터</p>
                            <span style={{color: '#ccc', margin: '0 12px'}}>|</span>
                            <p className="sub_tap_name hv" onClick={()=>goUrl('/event')}>이벤트</p>
                            <span style={{color: '#ccc', margin: '0 12px'}}>|</span>
                            {mbData?.email ? 
                            <>
                                <p className="sub_tap_name hv" onClick={() => onLogout()}>로그아웃</p>
                                <p className="sub_tap_name btn" onClick={()=>goUrl('/my/book')}>나의 교실</p>
                            </>
                            :
                            <>
                                <p className="sub_tap_name hv" style={{fontWeight:'700'}} onClick={() => goUrl(routes.login)}>로그인</p>
                                <p className="sub_tap_name btn" onClick={()=>goUrl('/agree')}>회원가입</p>
                            </>
                            }
                        </div>
                        
                    </div>
                </div>
                }
                
                <div className="header_part2 mobile" style={{display : 'none'}}>
                    <div className="header_part2_mobile_back">
                        <div className="header_title_box" onClick={()=>goMain()}>
                            <p className="title">창비교육</p>
                            <div className="sub_title_box">
                                <p className="sub_title sm">선생님을 위한</p>
                                <p className="sub_title">수업지원자료실</p>
                            </div>
                        </div>

                        <div className="header_top_sub" >
                            <img src='/images/menu-line.svg' onClick={()=>onMenuOpen()}/>
                        </div>
                    </div>
                </div>
   
                
                {((location.pathname.indexOf("notice")>=0 || location.pathname.indexOf("faq")>=0)) ? 
                    <>
                    {(isResize &&  window.innerWidth >= 800) &&
                    <div className="my-menu">
                        <Link to="/notice" className={location.pathname.indexOf("notice")>=0 ? 'active' : ''}>공지사항</Link>
                        <Link to="/faq" className={location.pathname.indexOf("faq")>=0 ? 'active' : ''}>자주하는 질문</Link>
                    </div>
                    }
                    <div className="navi">
                        <div><span onClick={()=>navigate("/notice")}>고객센터</span><strong>{location.pathname.indexOf("notice")>=0 ? '공지사항' : '자주하는 질문'}</strong></div>
                    </div>
                    </>
                : ((location.pathname.indexOf("/my/")>=0))  ?
                    <>
                    <div className="my-menu">
                        <Link to="/my/book" className={location.pathname=='/my/book' ? 'active' : ''}>내 교과서</Link>
                        <Link to="/my/scrap" className={location.pathname=='/my/scrap' ? 'active' : ''}>스크랩</Link>
                        <Link to="/my/qna" className={location.pathname=='/my/qna' ? 'active' : ''}>내 문의함</Link>
                        <Link to="/my/info" className={location.pathname=='/my/info' ? 'active' : ''}>내 정보</Link>
                    </div>
                    </>
                : ((location.pathname.indexOf("/event")>=0))  ?
                    <div className="navi">
                        <div>
                            <span onClick={(e)=>navigate("/event")}>이벤트</span>
                            
                            {(location.pathname.indexOf("/event/") >= 0 && eventTitle) && <span className="event_title">{window.innerWidth >= 340 ? eventTitle : '이벤트 상세'}</span>}
                        </div>
                    </div>
                :
                <></>
                }
                    <>
                   
                        <>
                            {isMainMenu ?
                                <>
                                <div className={`header_part3${menuOpen ? ' active' : ''}`}>
                                    <div className="header_box">
                                        <div className={`header_part3_sub`}>
                                            <div className="m_menu">
                                                <button type="button" className="close" onClick={()=>onMenuOpen()}><img src='/images/exit-icon.svg' /></button>
                                                {mbData?.email ? 
                                                <>
                                                <button type="button" onClick={() => onLogout()} className="logout">로그아웃</button>
                                                <div className="hidemenu">
                                                    <button type="button" onClick={()=>setMypageOpen(!isMypageOpen)}>마이페이지</button>
                                                    <ul className={isMypageOpen ? 'open' : ''}>
                                                        <li onClick={()=>goUrl('/my/book')}>내 교과서</li>
                                                        <li onClick={()=>goUrl('/my/scrap')}>스크랩</li>
                                                        <li onClick={()=>goUrl('/my/qna')}>내 문의함</li>
                                                        <li onClick={()=>goUrl('/my/info')}>내 정보</li>
                                                    </ul>
                                                </div>
                                                </>
                                                :
                                                <div>
                                                    <Link to="/login">로그인</Link>
                                                    <Link to="/agree" className="register" >회원가입</Link>
                                                </div>
                                                }
                                                <div className="hidemenu">
                                                    <button type="button"  onClick={()=>setCustomerOpen(!isCustomerOpen)}>고객센터</button>
                                                    <ul className={isCustomerOpen ? 'open' : ''}>
                                                        <li onClick={()=>goUrl('/notice')}>공지사항</li>
                                                        <li onClick={()=>goUrl('/faq')}>자주하는 질문</li>
                                                    </ul>
                                                </div>
                                                <Link to="/event">이벤트</Link>
                                            </div>                                        
                                            <div className="header_part3_cate_box">
                                                <p className={`tap language ${menuTab === 0 ? 'active' : ''}`} onMouseEnter={()=>CateClick(0)} onClick={()=>CateClick1(0)} onMouseLeave={()=>goCateOut()}>국어</p>
                                                <p className={`tap society ${menuTab === 1 ? 'active' : ''}`} onMouseEnter={()=>CateClick(1)} onClick={()=>CateClick1(1)} onMouseLeave={()=>goCateOut()} >사회</p>
                                                <p className={`tap art ${menuTab === 2 ? 'active' : ''}`} onMouseEnter={()=>CateClick(2)} onClick={()=>CateClick1(2)} onMouseLeave={()=>goCateOut()}>미술</p>
                                            </div>

                                            <div className="header_part3_cate_box two">
                                                
                                                <p className={`tap two ${menuTab===3 || location.pathname.indexOf("/innovation")>=0 || location.pathname.indexOf("/dictionary")>=0 || location.pathname.indexOf("/calendar")>=0 || location.pathname.indexOf("/month")>=0 ? 'active' : ''}`} onClick={()=>onMenu(3)}>수업 혁신</p>
                                                <p className={`tap two ${menuTab===4 || location.pathname.indexOf("/creative")>=0  ? 'active' : ''}`} onClick={()=>onMenu(4)}>창의적 체험 활동</p>
                                                <p className={`tap two ${menuTab===5 || location.pathname.indexOf("/book")>=0  ? 'active' : ''}`} onClick={()=>onMenu(5)}>수업 연계 도서</p>
                                                <p className={`tap two ${menuTab===6 || location.pathname.indexOf("/teacher")>=0  ? 'active' : ''}`} onClick={()=>onMenu(6)}>선생님 맞춤 자료</p>
                                            </div>
                                        </div>                                  
                                    </div>                            
                                </div>
                                
                                { isCateshow && 
                                <div onMouseEnter={()=>inMouseMenu()} onMouseLeave={()=>outMouseMenu()} className={`header_cate_hover_menu ${!topBanner ? '' : 'banner'} ${isScrolled ? 'scroll' : ''} ${topBanner && isScrolled ? 'clear' : ''} ${location.pathname.indexOf("/event") >=0 || location.pathname.indexOf("/notice") >=0 || location.pathname.indexOf("/faq") >=0   ? 'mnavi' : ''}`}>
                                    <div className="cate_bar_box">
                                        <div className="cate_bar">
                                   

                                            <select className="m_submenu" onChange={(e)=>onSubMenuOpen(e.target.value)}>
                                                {Object.entries(props.menu).map(([key, value]) => 
                                                    Object.entries(value).map(([key2, value2]) => 
                                                        menu === key ? 
                                                        <option value={key2} className={menu===key ? 'menu1 show' : 'menu1 hide'} selected={submenu===key2}>{key2}</option>
                                                        :
                                                        <></>
                                                    )
                                                )}    
                                            </select>
                                            <div className="menu_info">
                                            {Object.entries(props.menu_year).map(([key, value]) => 
                                                Object.entries(value).map(([key2, value2]) => 
                                                    <div className={menu==key ? 'menu1 show' : 'menu1 hide'}>
                                                        <button type="button" className={menu==key && submenu==key2 ? 'btn-menu1 active' : 'btn-menu1'} onMouseEnter={(e)=>onSubMenuOpen(key2)}> {key2}  </button>
                                                        <div className="box-menu1">
                                                        {
                                                            Object.entries(value2).map(([key3, value3]) => 
                                                                <div className={menu==key && submenu==key2 ? 'menu2 show' : 'menu2 hide'}>
                                                                    <strong className="btn-menu2" >{value3.year} 개정</strong>
                                                                    <div className="scroll-menu2">
                                                                        <div>
                                                                            {Object.entries(props.menu).map(([kkey, vvalue]) => kkey === key ? 
                                                                               <>
                                                                               {
                                                                                    Object.entries(vvalue).map(([kkey2, vvalue2]) => kkey2 === key2 ? 
                                                                                        <>
                                                                                        {
                                                                                            Object.entries(vvalue2).map(([kkey3, vvalue3]) => kkey3 == value3.year ?
                                                                                                <>
                                                                                                {vvalue3.map(vval=> <>
                                                                                                    <button type="button" className="btn-menu3" onClick={(e)=>onCurriculum(vval)}>{vval.name}</button>
                                                                                                </>)}
                                                                                                </>
                                                                                                :
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                        </> 
                                                                                        :
                                                                                        <></>    
                                                                                    )
                                                                               }
                                                                               </>
                                                                               :
                                                                               <></>
                                                                            )}
                                                                    
                                                                        {/*props.menu[key][key2][value3.year].map(val=> (
                                                                            <button type="button" className="btn-menu3" onClick={(e)=>onCurriculum(val)}>{val.name}</button>
                                                                        ))*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                       
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                            </div>

                                            <div className="exit_box">
                                                <span>
                                                    <img src='/images/x-icon-white.svg' onClick={()=>subMenuClose()}/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                } 
                                </>     
                            :
                            <></>
                            }
                        </>
                  
                    </>
               

                
                
            </div>
            {view &&
                <div className="back_blind">

                </div>
            }
        </div>
        
    )
}

