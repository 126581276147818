import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link,useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import images from "../../libs/images";
import PopupDictionary from '../popup/PopupDictionary';
import InnerMenu from "../../components/innovation_menu";
import Paging from "../../components/paging";
import Pagination from 'react-js-pagination';
import * as api from '../../libs/api';
import { imageUrl } from "../../libs/utils";
import BtnFileDownload from "../../components/file_download";
import BtnFileDownloadAll from "../../components/file_download_all";
import BtnScrap from "../../components/btn_scrap";
import BtnScrapAll from "../../components/btn_scrap_all";
import PopupMain from "../popup/PopupMain";


export default function Page() {

    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);

    const location = useLocation();    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { type, id } = useParams();
    
    let [level_seq, setLevelSeq] = useState(0);

    
    
    const [all_checked, setAllChecked] = useState(false);
    const [info, setInfo] = useState({});
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [page_range, setPageRagne] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(10);
    const [title, setTitle] = useState(['국어','사회','미술']);
    const [sub_title, setSubTitle] = useState([]);
    let [search_text, setSearchText] = useState("");
    const [unit, setUnit] = useState([]);
    const [unit_level, setUnitLevel] = useState([]);
    
    const [count, setCount] = useState([]);
    const [count_total, setCountTotal] = useState([]);

 
    const [checkItems, setCheckItems] = useState([]);
    
    useEffect(() => {
        
        api.getData("/dictionary/unit", {}).then(res=>{
 
            if(res && res?.data?.status=="SUCCESS"){
                const arr = [''];
                res.data.data.map(val=>{
                    if(val.name !="국어"){
                        arr.push(val.name);
                    }
                })

                setSubTitle(arr)
                setUnit(res.data.data);               
            }
        });
        onTab(0,"","");
        //사회,미술
        if( id == 12 || id == 13){
           
            api.getData("/dictionary/unit/tab", {
                unitSeq:id
            }).then(res=>{
                if(res && res?.data?.status=="SUCCESS"){
                    setUnitLevel(res.data.data);               

                    let arraysum = 0;
                    res.data.data.map(val=>{
                        arraysum += Number(val.cnt);
                    })
                    setCountTotal(arraysum);

                }
            });
        }
    },[type, id])


    const onTab = (ls,st) => {
        onList(1, ls,st);
    }
  
    const onList = (page, vv, st) => {    
        if(vv >= 0 ){
            level_seq = vv;
        }
        setLevelSeq(level_seq);
        search_text = st;        
        setSearchText(search_text);
        api.getData("/dictionary" ,{
            unitSeq:id,
            unitLevelSeq:(level_seq > 0 ? level_seq : ''),
            text:search_text ? search_text : '',
            page:page-1,
            size:limit,
        }).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);                   
                //setCount(res.data.data.dictionaryJsonList);

            }
        });
    }

    function goUrl(url) {
        navigate(url);
    }
    
    const onPopup = (type) => {
        dispatch(
            open({
                component: <PopupDictionary 
                    type={type}
                    id={id}
                    title={sub_title[id]}
                />,
                
            })
        );
    }
    
    const onSearch = () => {
        
            onList(1,level_seq,search_text)  
        
    }

    const onAllChecked = (e) => {

        if(e.target.checked){
            const idArray = [];
            list.forEach((el) => idArray.push(el.dictionarySeq));
            setCheckItems(idArray);
        } else {
            setCheckItems([]);
        }

        setList(
            list.map(par=>
               par.dictionarySeq > 0 ? { ...par, checked: e.target.checked } : par
            )
        )
    }

    const onChecked = (e,val) => {
        //console.log(e.target.checked)
        if( e.target.checked ){
            setCheckItems(prev => [...prev, val.dictionarySeq]);
        }else{
            setCheckItems(checkItems.filter((el) => el !== val.dictionarySeq));
        }

        setList(
            list.map(par=>
                par.dictionarySeq === val.dictionarySeq ? { ...par, checked: e.target.checked } : par
            )
        )
        
 

    }
   
    return (
        <div className="changbi">
            <div className="wrap">
                <InnerMenu />          
                <div className="box dictionary">
                    <div style={{marginBottom: "20px"}}>
                        <img src="/images/dictionary_banner.jpg" width="100%"/>
                     </div>
                    {location.pathname.indexOf('/dictionary/0') >= 0 ?
                    <>
                    <h1 className="title">수업 고민 사전({sub_title[id] ? sub_title[id] : '국어'})</h1>
                    <div className="list-button">
                       {unit.find(val=>val.name===sub_title[id])?.file &&
                       <BtnFileDownload class="dic" folder="dictionary" text="수업 고민 사전 한 번에 받기" filename={unit.find(val=>val.name===sub_title[id])?.file} filename_original={unit.find(val=>val.name===sub_title[id])?.oriFile}/>
                       }
                       {id == 0 ? <BtnFileDownload class="dic" folder="dictionary" text="수업 고민 사전 한 번에 받기" filename={unit.find(val=>val.unitSeq == 14)?.file} filename_original={unit.find(val=>val.unitSeq == 14)?.oriFile}/> : <></>}
                       {id > 0 && <>
                       <button type="button" onClick={()=>onPopup(1)}><img src='/images/icon_search.svg' /> 단원별 용어 보기</button>
                       <button type="button" onClick={()=>onPopup(2)}><img src='/images/icon_search.svg' /> 영역별 용어 보기</button>
                       </>}
                    </div>
                    </>
                    : location.pathname.indexOf('/dictionary/1') >= 0 ?
                    <>
                    <h1 className="title">수업 고민 사전(사회)</h1>
                    <div className="list-button _01">
                        {unit.find(val=>val.name==="사회")?.file &&
                        <BtnFileDownload class="dic"  folder="dictionary" text="수업 고민 사전 한 번에 받기" filename={unit.find(val=>val.name==="사회")?.file} filename_original={unit.find(val=>val.name==="사회")?.oriFile}/>
                        }
                        {/*<button type="button" onClick={()=>onPopup(1)}><img src='/images/icon_search.svg' /> 단원별 용어 보기</button>*/}
                    </div>
                    {/*unit_level.length > 0 && (
                    <div className="tab _01">
                       {unit_level.length > 0 && (<button type="button" onClick={(e)=>onTab(0,search_text)} className={level_seq === 0 && 'active'}>전체 <strong>{count_total}</strong></button>)}
                       {unit_level.map(par=>(
                        <button type="button" onClick={(e)=>onTab(par.unitLevelSeq,search_text)} className={level_seq === par.unitLevelSeq && 'active'}>{par.name} <strong>{par.cnt}</strong></button>
                        ))}
                    </div>
                    )*/}
                    </>
                    : 
                    <>
                    <h1 className="title">수업 고민 사전(미술)</h1>
                    <div className="list-button _02">
                        {unit.find(val=>val.name==="미술")?.file  &&
                        <BtnFileDownload class="dic"  folder="dictionary" text="수업 고민 사전 한 번에 받기" filename={unit.find(val=>val.name==="미술")?.file} filename_original={unit.find(val=>val.name==="미술")?.oriFile} />
                        }
                    </div>
                    {/*unit_level.length > 0 && (
                    <div className="tab _02">
                        {unit_level.length > 0 && (<button type="button" onClick={(e)=>onTab(0,search_text)} className={level_seq === 0 && 'active'}>전체 <strong>{count_total}</strong></button>)}
                        {unit_level.map(par=>(
                        <button type="button" onClick={(e)=>onTab(par.unitLevelSeq,search_text)} className={level_seq === par.unitLevelSeq && 'active'}>{par.name} <strong>{par.cnt}</strong></button>
                        ))}
                    </div>
                    )*/}
                    </>
                    }
           
                    <div className="list">
                        <dl>
                            <dt>
                                <label className="check"><input type="checkbox" onChange={(e)=>onAllChecked(e)} checked={checkItems.length === list.length && list.length > 0 ? true : false}/><span>전체 선택</span></label>
                                <BtnScrapAll list={list} type="1" />
                                <BtnFileDownloadAll folder="dictionary" list={list} name="addFile" ori="addFileOri"/>
                                <div className="search">
                                    <input type="text" onChange={(e)=>setSearchText(e.target.value)} value={search_text}/>
                                    {search_text ? 
                                    <button type="button" onClick={(e)=>setSearchText("")} className="text-remove"><img src='/images/close-circle-fill.svg' /></button>
                                    :
                                    <></>
                                    }
                                    <button type="button" onClick={(e)=>onSearch(e)}><img src='/images/icon_search.svg' /></button>
                                </div>
                            </dt>
                            {list.map((val) => (
                                
                            <dd>
                                <label className="check"><input type="checkbox" checked={checkItems.includes(val.dictionarySeq) ? true : false} onChange={(e)=>onChecked(e,val)}/></label>
                                <span>{val.name ? val.name : '제목 없음'}</span>
                                <div className='curr'>
                   
                                    {val.addFile.indexOf(".pdf")>=0 ? <button type="button" ><a href={api.url+"/pdf?folder=dictionary&file="+encodeURIComponent(val.addFile)} target="_blank"><img src='/images/button_search.svg' /></a></button> : ''}
                                    <BtnScrap info={val} type="1"/>
                                    {val.addFile &&
                                    <BtnFileDownload folder="dictionary" text="" filename={val.addFile} filename_original={val.addFileOri} image='/images/button_download.svg'/>
                                    }
                                </div>
                            </dd>
                            ))}
                        </dl>
                        {list.length <= 0 ?
                        <p className="nolist">작성된 내용이 없습니다.</p>            
                        : ''
                        }
                    </div>                    
                    {list.length > 0 ?
                    <div className="paging">
                        <Pagination
                            activePage={page+1}
                            itemsCountPerPage={limit}
                            totalItemsCount={total}
                            pageRangeDisplayed={page_range}
                            itemClassFirst="page_first"
                            itemClassPrev="page_prev"
                            itemClassNext="page_next"
                            itemClassLast="page_last"
                            onChange={onList}
                        />
                    </div>
                    : '' }
       
                </div>
            </div>
        </div>
    );
}
