import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import InnerMenu from "../../components/innovation_menu";
import PopupMain from "../popup/PopupMain";


import * as api from '../../libs/api';
import { imageUrl } from "../../libs/utils";

export default function Page() {
    const [tab, setTab] = useState(1);
    const months = [1,2,3,4,5,6,7,8,9,10,11,12];
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [title, setTitle] = useState(['국어','사회','미술']);

    const onMonth = (month) => {
        
        api.getData("/calendar/"+month, {}).then(res=>{

            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data);
                if(res.data.data.length > 0){
                    setTab(res.data.data[0].month);
                } else {
                    setTab(month);
                }
            }
        });
    }
    useEffect(() => {
        const date = new Date()
        let current_month = date.getMonth() + 1;
        if(location?.state?.month){
            onMonth(location?.state?.month);
        } else {
            if(current_month + 1 >= 13){
                current_month = 0;
            }
            onMonth(current_month + 1);
        }
    },[])

    const location = useLocation();
    const navigate = useNavigate();
    
    function goUrl(url) {
        navigate(url);
    }

    return (
        <div className="changbi">
            <div className="wrap">
                <InnerMenu m_title="수업 혁신" />
                <div className="box">
                    <h1 className="title">수업 캘린더</h1>
                    <p className="comment">창비교육이 제안하는 월별 수업자료로 시기/학년별 선택부터 독후활동까지 해결할 수 있도록 하였습니다.</p>
                    <div className="tab">
                        {months.map((val) => (
                        <button type="button" className={tab===val ? 'active' : ''} onClick={(e)=>onMonth(val)}>{val}월</button>
                        ))}
                    </div>
                    <div className="list-type3">
                        {title.map((head, key)=>(
                        <div className={"type"+key}>
                            <h2>{head}</h2>
                            <div className="scroll">
                                <ul>
                                    {list.map((val) => (
                                        val.subject===head && (
                                            <li onClick={()=>navigate("/calendar/"+key+"/"+val.innovationSeq,{state:{link:location.pathname, month:tab}})}>
                                                <img src={imageUrl+"dictionary?file="+val.fileThumbnail} width="100" height="100"/>
                                                <p>
                                                    <span>{val.title}</span>
                                                    <small>{val.school}</small>
                                                    <small>{val.subject}</small>
                                                </p>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            </div>
                        </div>
                        ))}




                    </div>
                </div>
            </div>
        </div>
    );
}
