/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import routes from '../libs/routes';
import images from "../libs/images";
import * as api from '../libs/api';

export default function(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [menu, setMenu] = useState("/calenda"); 
    const [step, setStep] = useState(false); 
    const [openMobileMenu, setOpenMobileMenu] = useState(false); 
    const [submenu, setSubmenu] = useState([]); 

    const onMenu = (link, type) => {
        if(type){
            
        }else{
            setStep(false);
        }
        
        navigate(link);
        setMenu(link);        
    }
          
    useEffect(() => {
        api.getData("/dictionary/unit", {
        }).then(res=>{
            if(res && res?.data?.status === "SUCCESS"){
                setSubmenu(res.data.data);
            }
        });
        setMenu(location.pathname);  
    },[]);
    const onStep = () => {
        setStep(true);
        onMenu("/dictionary/0/0",0)
    }
    const onMobileMenu = (type) => {
        setOpenMobileMenu(type);
        if(openMobileMenu === type){
            setOpenMobileMenu(false);
        }
        if(type === 1){
            navigate("/dictionary");
        }
        if(type === 0){
            navigate("/innovation");
        }
        
    }
    let prev_link='';
    const onNavi = (e) => {
        if ( e.target.value === prev_link){
            navigate(prev_link);
        }
        prev_link = e.target.value;
    }
    const { m_title } = props;
    return (
        <div className="inno-menu">
            <h1 className="m_title">{m_title ? m_title : '수업 혁신'}</h1>
            <dl className={openMobileMenu === 0 ? 'active' : ''}>
                <dt onClick={()=>onMobileMenu(0)}>이달의 수업</dt>
                <dd onClick={()=>onMenu("/calendar",0)} className={location.pathname.indexOf("/calendar") >= 0 ? 'active' : ''}><span>캘린더</span></dd>
                <dd onClick={()=>onMenu("/month/0",0)} className={location.pathname.indexOf("/month/0") >= 0 ? 'active' : ''}><span>국어</span></dd>
                <dd onClick={()=>onMenu("/month/1",0)} className={location.pathname.indexOf("/month/1") >= 0 ? 'active' : ''}><span>사회</span></dd>
                <dd onClick={()=>onMenu("/month/2",0)} className={location.pathname.indexOf("/month/2") >= 0 ? 'active' : ''}><span>미술</span></dd>
            </dl>
            <select onChange={(e)=>navigate(e.target.value)}  onClick={(e)=>onNavi(e)}>
                <option value="/innovation" selected={location.pathname.indexOf("/innovation")>=0}>이달의 수업</option>
                <option value="/calendar" selected={location.pathname.indexOf("/calendar")>=0}>캘린더</option>
                <option value="/month/0" selected={location.pathname.indexOf("/month/0")>=0}>국어</option>
                <option value="/month/1" selected={location.pathname.indexOf("/month/1")>=0}>사회</option>
                <option value="/month/2" selected={location.pathname.indexOf("/month/2")>=0}>미술</option>
            </select>
            <dl className={openMobileMenu === 1 ? 'active' : ''}>
                <dt onClick={()=>onMobileMenu(1)}>수업 고민 사전</dt>
                <dd>
                    <span onClick={()=>onStep()} className={menu.indexOf("/dictionary/0")>=0 ? 'active' : ''}>국어</span>
                    {/*
                    <ul className={(step || location.pathname.indexOf("/dictionary/0") >= 0) ? 'active' : ''}>
                        {submenu?.map(val=>
                            val.subject ==='국어' && val.sort > 1 ? 
                            <li onClick={()=>onMenu("/dictionary/0/"+val.unitSeq,1)} className={location.pathname.indexOf("/dictionary/0/"+val.unitSeq) >= 0 ? 'active' : ''}>{val.name}</li>
                            : 
                            <></>
                        )}
                    </ul>
                    */}
                </dd>
                <dd onClick={()=>onMenu("/dictionary/1/12",0)} className={location.pathname.indexOf("/dictionary/1/12") >= 0 ? 'active' : ''}><span>사회</span></dd>
                <dd onClick={()=>onMenu("/dictionary/2/13",0)} className={location.pathname.indexOf("/dictionary/2/13") >= 0 ? 'active' : ''}><span>미술</span></dd>
            </dl>
            <select onChange={(e)=>navigate(e.target.value)} onClick={(e)=>onNavi(e)}>
                <option value="/dictionary">수업 고민 사전</option>
                <option value="/dictionary/0">국어</option>
                {submenu?.map(val=>
                    (val.subject ==='국어' && val.sort > 1) && (<option value={"/dictionary/0/"+val.unitSeq}>&nbsp;&nbsp;&nbsp;&nbsp;{val.name}</option>)
                )}
                <option value="/dictionary/1/12">사회</option>
                <option value="/dictionary/2/13">미술</option>
            </select>
        </div>  
    )
}