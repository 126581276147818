import React, { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";

import { Helmet } from 'react-helmet-async';


export default function Metas(props) {
    const location = useLocation();
    
    const title = "(주)창비교육";
    const link = "https://class.changbiedu.com";
    
    const [meta_title, setMetaTitle] = useState(title);
    const [meta_link, setMetaLink] = useState(link);

    useEffect(() => {  
        if( location.pathname === "/login" ){
            setMetaTitle(title+" 로그인");
        } else if( location.pathname.indexOf("curriculum/0") >= 0 ){
            setMetaTitle(title+" 국어");
        } else if( location.pathname.indexOf("curriculum/1") >= 0 ){
            setMetaTitle(title+" 사회");
        } else if( location.pathname.indexOf("curriculum/2") >= 0 ){
            setMetaTitle(title+" 미술");
        } else if( location.pathname.indexOf("innovation") >= 0
        || location.pathname.indexOf("calendar") >= 0 
        || location.pathname.indexOf("month") >= 0 
        || location.pathname.indexOf("dictionary") >= 0 
        ){
            setMetaTitle(title+" 수업혁신");
        } else if( location.pathname.indexOf("creative") >= 0 ){
            setMetaTitle(title+" 창의적 체험 활동");
        } else if( location.pathname.indexOf("book") >= 0 ){
            setMetaTitle(title+" 수업 연계 도서");
        } else if( location.pathname.indexOf("teacher") >= 0 ){
            setMetaTitle(title+" 선생님 맞춤 자료");
        } else if( location.pathname.indexOf("notice") >= 0 ){
            setMetaTitle(title+" 공지사항");
        } else if( location.pathname.indexOf("faq") >= 0 ){
            setMetaTitle(title+" 자주하는 질문");
        } else if( location.pathname.indexOf("event") >= 0 ){
            setMetaTitle(title+" 이벤트");
        } else if( location.pathname.indexOf("update") >= 0 ){
            setMetaTitle(title+" 업데이트 자료");
        } else {
            setMetaTitle(title);
        }

        setMetaLink(location.pathname);
    },[location.pathname]);

    return (
        <Helmet>
            <title>{meta_title}</title>
            <meta property="og:site_name" content={meta_title} />
            <meta property="og:title" content={meta_title} />
            <meta property="og:url" content={meta_link} />
            <meta property="og:description" content={meta_title} />
        </Helmet>
    );
}

