import React, { useEffect, useState, useRef } from 'react';

import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../../redux/popupSlice';
import { logIn, logOut  } from '../../redux/usersSlice';
import images from "../../libs/images";
import PopupMain from '../popup/PopupMain';
import * as api from '../../libs/api';
import { imageUrl } from "../../libs/utils";
import BtnFileDownload from '../../components/file_download';

export default function QnaForm() {
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tab, setTab] = useState(1);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [page_range, setPageRagne] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(10);
    const [form, setForm] = useState({});
    const [file_list, setFileList] = useState([]);

    
    useEffect(() => {
        api.getDataRefresh("/member", {
            userSeq:mbData.userSeq
        },mbData).then(res=>{
 
            if(res && res?.data?.status=="SUCCESS"){
            }else{
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />,
                    })
                );  
                navigate("/login");  
            }
        });
    },[]);


    const onFileRemove = (e) => {
        setFileList(file_list.filter((val)=> val.name !== e.name ));
    }
    const onFileAdd = (e) => {
        if(file_list.length < 3){
            const aa = e.target.name;
            const {files} = e.target;
            const uploadFile = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(uploadFile);
            reader.onloadend = ()=> {
                console.log(uploadFile.size);
                if(uploadFile.size > 10000000){
                    dispatch(
                        open({
                            component: <PopupMain
                                width={400}
                                text="첨부파일은 10MB이하만 가능합니다."
                                button="닫기"
                            />
                        })
                    );
                }else{
                    setFileList([...file_list, uploadFile]);
                }
            }
        }else{
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="첨부파일은 3개까지 가능합니다."
                        button="닫기"
                    />
                })
            );
        }
    }
    const onQnaSave = () => {
        let msg = "";
        if(!form?.qnaMemo){
            msg = "내용을 선택해주세요.";
        }
        if(!form?.title){
            msg = "제목을 작성해주세요.";
        }
        if(!form?.type){
            msg = "문의 유형을 선택해주세요.";
        }
        if(msg!=""){
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text={msg}
                        button="닫기"
                    />
                })
            );
        } else {
            const formData = new FormData();

            formData.append("type",form?.type);
            formData.append("title",form?.title);
            formData.append("qnaMemo",form?.qnaMemo);
            formData.append("memberSeq",mbData.userSeq);


            if(file_list[0]){
                formData.append("files1",file_list[0]);
            }
            if(file_list[1]){
                formData.append("files2",file_list[1]);
            }
            if(file_list[2]){
                formData.append("files3",file_list[2]);
            }  
            api.fileUploadRefresh("/qna",formData,mbData).then(res=>{
                navigate("/my/qna");
            });
        }
    }
    return (
        <div className="changbi">
            <div className="wrap">


            <div className="my qna">
                    <h1 className="title none">1:1 문의<button type="button" onClick={()=>navigate("/faq")}><img src='/images/icon_qna.svg' />자주하는 질문</button></h1>
                    <p className="comment">문의사항을 남겨주시면 빠른 시간내로 답변을 남겨드리겠습니다.</p>
                    <form >
                        <select onChange={(e)=>setForm({...form, type:e.target.value})} >
                            <option>문의 유형 선택</option>
                            <option value="교과서">교과서</option>
                            <option value="참고서">참고서</option>
                            <option value="이벤트">이벤트</option>
                            <option value="오류신고">오류신고</option>
                            <option value="제안 및 개선사항">제안 및 개선사항</option>
                            <option value="기타">기타</option>
                        </select>
                        <input type="text" placeholder="제목을 입력해 주세요"  onChange={(e)=>setForm({...form, title:e.target.value})}/>
                        <textarea placeholder="내용을 입력해 주세요." onChange={(e)=>setForm({...form, qnaMemo:e.target.value})}></textarea>
                        <div className="file">
                            <label><input type="file" onChange={(e)=>onFileAdd(e)} /><button type="button">파일첨부</button></label><span>파일은 최대 3개, 파일당 10MB까지 첨부 가능합니다.</span>
                        </div>
                        <div className="add_file">
                            {file_list?.map((val,key)=>
                                <span>{val.name}<button type="button" onClick={(e)=>onFileRemove(val)}><img src='/images/remove.svg' width="16"/></button></span>
                            )}
                        </div>
                        <div className="write_button">
                            <button type="button" onClick={()=>navigate(-1)}>취소</button>
                            <button type="button" onClick={()=>onQnaSave()}>문의하기</button>
                        </div>
                    </form>
                </div>
               
            </div>
        </div>
    );
}
